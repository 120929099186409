const blockchainImages = new Map();

blockchainImages.set(
  'stellar',
  'https://assets.coingecko.com/coins/images/100/large/Stellar_symbol_black_RGB.png?1552356157',
);
blockchainImages.set('cosmos', 'https://assets.coingecko.com/coins/images/1481/large/cosmos_hub.png?1555657960');
blockchainImages.set('icon', 'https://assets.coingecko.com/coins/images/1060/large/icon-icx-logo.png?1547035003');
blockchainImages.set(
  'ripple',
  'https://assets.coingecko.com/coins/images/44/large/xrp-symbol-white-128.png?1605778731',
);
blockchainImages.set('tron', 'https://assets.coingecko.com/coins/images/1094/large/tron-logo.png?1547035066');
blockchainImages.set('neo', 'https://assets.coingecko.com/coins/images/480/large/NEO_512_512.png?1594357361');
blockchainImages.set('bitcoin', 'https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579');
blockchainImages.set('liquid', 'https://assets.coingecko.com/markets/images/40/large/liquid.png?1536133472');
blockchainImages.set('ethereum', 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880');
blockchainImages.set(
  'binancechain',
  'https://assets.coingecko.com/coins/images/825/large/binance-coin-logo.png?1547034615',
);
blockchainImages.set('steem', 'https://assets.coingecko.com/coins/images/398/large/steem.png?1547034126');
blockchainImages.set('tezos', 'https://assets.coingecko.com/coins/images/976/large/Tezos-logo.png?1547034862');
blockchainImages.set(
  'hive',
  'https://assets.coingecko.com/coins/images/10840/large/logo_transparent_4x.png?1584623184',
);
blockchainImages.set('eos', 'https://assets.coingecko.com/coins/images/738/large/eos-eos-logo.png?1547034481');

export default blockchainImages;
